@font-face {
  font-family: 'Diatype Pre';
  src: url('../../fonts/DiatypePre-Medium.woff2') format('woff2'),
       url('../../fonts/DiatypePre-Medium.woff') format('woff'),
       url('../../fonts/DiatypePre-Medium.eot') format('eot');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  font-family: "Diatype Pre", helvetica, sans-serif;
  font-size: 100%;
  font-size: 19px;
  line-height: 1.19em;
}

::placeholder {
  color: black;
}

h1 {
  display: block;
  width: 191px;
  height: 88px;
  margin: 50px auto;
  background: url("../../img/logo.png") no-repeat center center;
  background-size: 191px 88px;
  text-indent: -9999px;
}

main {
  max-width: 668px;
  margin: 185px auto 50px;
}

.container {
  padding: 0 20px;
}

a {
  &:visited {
    color: blue;
  }

  &:hover {
    cursor: pointer;
  }
}

nav {
  margin: 0 0 30px 0;

  ul {
    padding: 0;
    list-style: none;
    overflow: hidden;

    li {
      &:first-child {
        float: left;
      }
  
      &:last-child {
        float: right;
      }

      &:hover {
        cursor: pointer;
      }

      span {
        color: blue;
        text-decoration: underline;
      }
    }
  }
}

.small-txt {
  font-size: 16px;
  line-height: 1.19em;

  a {
    text-decoration: none;
  }
}

.form {
  width: 100%;

  .flex-container {
    width: 100%;

    @media (min-width: 768px) {
      display: flex;
      align-items: flex-start;
    }
  }

  input:not([type="checkbox"]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background: transparent;
    border: 0;
    border: 1px solid black;
    font-family: "Diatype Pre", helvetica, sans-serif;
    font-size: 100%;
    font-size: 19px;
    line-height: 1.19em;
    padding: 14px 17px 11px;
    display: block;
    box-sizing: border-box;
    width: 100%;
    outline: 0;
    border-radius: 0;

    @media (max-width: 768px) {
      padding: 14px 17px 9px;
    }
  }

  &__name,
  &__surname {
    margin-bottom: 16px;

    @media (min-width: 768px) {
      width: calc(50% - 20px);
    }
  }

  &__name {
    margin-right: 16px;
  }

  &__email {
    margin-bottom: 25px;
  }

  &__legal {
    position: relative;
    padding-left: 38px;
    display: block;
    margin-bottom: 27px;

    &__checkbox {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border: 0;
      border: 1px solid black;
      -webkit-appearance: none;
      border-radius: 0;
      outline: 0;
      cursor: pointer;

      &:checked {
        background: url("../../img/tick.png") no-repeat center center;
        background-size: 20px 20px;
      }
    }

    span {
      display: inline-block;
      margin-top: 5px;
      line-height: 1em;
    }
  }

  .has-error {
    border: 1px solid #F00 !important;
    color: #F00;
  }

  &__submit {
    // -webkit-appearance: none;
    border-radius: 0;
    border: 0;
    border: 1px solid rgb(207,207,207);
    background-color: rgb(240,240,240);
    font-family: "Diatype Pre", helvetica, sans-serif;
    font-size: 100%;
    font-size: 19px;
    line-height: 1.19em;
    padding: 14px 17px 11px;
    display: block;
    box-sizing: border-box;
    margin: 0 auto;
    width: 118px;
    outline: 0;
    cursor: pointer;
    color: black;
  }
}

// selected states

form,
.about,
.terms,
.main-list,
.terms-list {
  display: none;
}

.form-active,
.about-active {
  .main-list {
    display: block;
  }
}

.terms-active {
  .terms-list {
    display: block;
  }
}

.form-active {
  form {
    display: block;
  }

  #form-item {
    color: black;
    text-decoration: none;
  }
}

.about-active {
  .about {
    display: block;
  }

  #about-item {
    color: black;
    text-decoration: none;
  }
}

.terms-active {
  .terms {
    display: block;
  }

  .terms-item {
    color: black;
    text-decoration: none;
  }

  #close-terms {
    display: inline-block;
  }
}